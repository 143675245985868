import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { throttle } from 'lodash';
import TrackingService from '@/services/tracking';
import { useRouter } from 'next/router';
import { Box, IconButton, Text } from '@design-system/pc';
import { TOP_BUTTON_Z_INDEX } from '@/styles';

type RootRefType = typeof window | null;

export interface FloatingGroupProps {
  onlyTopButton?: boolean;
  zIndex?: number;
}

const FloatingGroup = ({ onlyTopButton, zIndex }: FloatingGroupProps) => {
  const router = useRouter();
  const rootRef = useRef<RootRefType>(null);
  const [isShow, setIsShow] = useState<boolean>(false);
  const [scrollY, setScrollY] = useState<number>(0);

  const handleClickScrollTop = useCallback(() => {
    if (!rootRef?.current) return;

    rootRef.current.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const handleWatchScroll = throttle(() => {
    const currentScrollY = rootRef?.current?.scrollY ?? 0;

    setScrollY(currentScrollY);
  }, 300);

  const handleChangeButtonExposure = useCallback(() => {
    const exposurePoint = window?.innerHeight / 3;
    setIsShow(scrollY > exposurePoint);
  }, [scrollY]);

  const handleGoQuickConsult = useCallback(() => {
    router.push(
      // `${process.env.NEXT_PUBLIC_REMODELING_WEB_HOST}/quick-consult/request?channelType=OPKG_02`,
      `${process.env.NEXT_PUBLIC_REMODELING_WEB_HOST}/seq-consult?channelType=OP11_02`,
    );
  }, [router]);

  const initScrollEvent = () => {
    rootRef.current = window;
    rootRef.current.addEventListener('scroll', handleWatchScroll);
  };

  const destroyScrollEvent = () => {
    if (rootRef?.current === null) return;
    rootRef.current.removeEventListener('scroll', handleWatchScroll);
  };

  useEffect(() => {
    initScrollEvent();

    return () => {
      destroyScrollEvent();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleChangeButtonExposure();
  }, [handleChangeButtonExposure]);

  return (
    <Box
      position="fixed"
      right={80}
      bottom={40}
      width={72}
      height={72}
      zIndex={zIndex || TOP_BUTTON_Z_INDEX}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      {!onlyTopButton && (
        <ConsultButton
          onClick={handleGoQuickConsult}
          isShow={isShow}
          {...TrackingService.tagGTM('quick_consult_button')}
        >
          <Text variant="headline7" color="color_chip.white" textAlign="center">
            무료
            <br />
            견적 상담
          </Text>
        </ConsultButton>
      )}
      <ScrollTopButtonWrapper
        isShow={isShow}
        width={52}
        height={52}
        position="absolute"
        right="10px"
        bottom="0"
        {...TrackingService.tagGTM('scroll_top_button')}
      >
        <ScrollTopButton onClick={handleClickScrollTop}>
          <IconButton size="lg" variant="ic_arrow_top" />
        </ScrollTopButton>
      </ScrollTopButtonWrapper>
    </Box>
  );
};

export default FloatingGroup;

const ConsultButton = styled(Box)<{ isShow: boolean }>`
  width: 72px;
  height: 72px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.color_chip.primary};
  position: absolute;
  right: 0;
  bottom: ${({ isShow }) => (isShow ? '66px' : 0)};
  transition: bottom 0.4s ease;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
  cursor: pointer;
`;

const ScrollTopButtonWrapper = styled(Box)<{ isShow: boolean }>`
  transform: scale(${({ isShow }) => (isShow ? 1 : 0)});
  transition: transform 0.2s;
`;

const ScrollTopButton = styled(Box)`
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.color_chip.white};
  border: 1px solid ${(props) => props.theme.colors.color_chip.gray[100]};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
